import { useEffect, useState } from 'react'

import MessageWithLink from './MessageWithLink'
import { getLinkObjectFromMessage } from './utils'

type Props = {
  message: string
}

export default function MessageContent(props: Props) {
  const { message } = props
  const [messageLink, setMessageLink] = useState(undefined)

  useEffect(() => {
    const linkObj = getLinkObjectFromMessage(message)

    if (linkObj) {
      setMessageLink(linkObj)
    }
  }, [message])

  if (!messageLink) {
    return null
  }
  if (messageLink?.title) {
    return <MessageWithLink message={message} linkOptions={messageLink} />
  }

  return <>{message}</>
}
