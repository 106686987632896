import type { GameProduct } from '@patrianna/shared-patrianna-types/store/GameModule'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type { PlayedGamesState } from 'store/types/PlayedGamesModule'

const initialState: PlayedGamesState = {
  games: null,
}

export const playedGamesSlice = createSlice({
  name: 'playedGames',
  initialState,
  reducers: {
    clearPlayedGames: () => initialState,

    setPlayedGames: (state, action: PayloadAction<{ games: Array<GameProduct> }>) => {
      state.games = action.payload.games
    },
  },
})

export type ActionsTypes = ReturnType<InferValueTypes<typeof playedGamesSlice.actions>>

export const { actions } = playedGamesSlice
