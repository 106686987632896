import type { GameProduct } from '@patrianna/shared-patrianna-types/store/GameModule'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type { SlotGameFlowState } from 'store/types/SlotGameFlowModule'

const initialState: SlotGameFlowState = {
  activeGameCode: null,
  activeGameTags: [],
  gameBet: null,
  goldMinBet: null,
  sweepstakeMinBet: null,
  lowOnCoinsLastDateOpened: null,
}

export const slotGameFlowSlice = createSlice({
  name: 'slotGameFlow',
  initialState,
  reducers: {
    setActiveGameCode: (state, action: PayloadAction<{ code: string }>) => {
      state.activeGameCode = action.payload.code
    },

    setActiveGameTags: (state, action: PayloadAction<{ tags: GameProduct['tags'] }>) => {
      state.activeGameTags = action.payload.tags
    },

    setGoldMinBet: (state, action: PayloadAction<{ goldMinBet: number }>) => {
      state.goldMinBet = action.payload.goldMinBet
    },

    setSweepstakeMinBet: (state, action: PayloadAction<{ sweepstakeMinBet: number }>) => {
      state.sweepstakeMinBet = action.payload.sweepstakeMinBet
    },

    setLowOnCoinsLastDateOpened: (state, action: PayloadAction<{ lowOnCoinsLastDateOpened: number }>) => {
      state.lowOnCoinsLastDateOpened = action.payload.lowOnCoinsLastDateOpened
    },

    clearSlotGameFlow: () => initialState,
  },
})

export type ActionsTypes = ReturnType<InferValueTypes<typeof slotGameFlowSlice.actions>>
export const { actions } = slotGameFlowSlice
