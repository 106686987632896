/**
 * Removes specified keys from the query string or URLSearchParams object, based on wholeNameKeys and partNameKeys.
 *
 * @param {Object} params - The parameters object.
 * @param {URLSearchParams | string} params.query - The query string or URLSearchParams object from which to remove keys.
 * @param {string[]} [params.wholeNameKeys] - Array of keys to be removed from the query by exact match.
 * @param {string[]} [params.partNameKeys] - Array of keys or key parts to be removed from the query by partial match.
 *
 * @returns {string} - The query string with specified keys removed. Returns an empty string if the query is empty after key removal.
 *
 *
 * @example
 * removeQueryByKeys({
 *   query: 'dialogName=test&isReplaceDisable=true&dialogProps.code=test',
 *   wholeNameKeys: ['dialogName', 'isReplaceDisable']
 * });
 * // Returns: '?dialogProps.code=test'
 *
 * @example
 * removeQueryByKeys({
 *   query: 'dialogName=test&isReplaceDisable=true&dialogProps.code=test&dialogProps.reason=test',
 *   partNameKeys: ['dialogProps.']
 * });
 * // Returns: '?dialogName=test&isReplaceDisable=true'
 */
export const removeQueryByKeys = ({
  query,
  wholeNameKeys,
  partNameKeys,
}: {
  query: URLSearchParams | string
  wholeNameKeys?: string[]
  partNameKeys?: string[]
}): string => {
  const searchParams = new URLSearchParams(query)

  // [FYI]: Remove keys that exactly match those in wholeNameKeys
  if (wholeNameKeys?.length > 0) {
    wholeNameKeys.forEach((key) => {
      if (searchParams?.has(key)) {
        searchParams?.delete(key)
      }
    })
  }

  // [FYI]: Remove keys that partially match those in partNameKeys
  if (partNameKeys?.length > 0) {
    const paramsKeys = [...searchParams?.keys()]

    paramsKeys.forEach((key) => {
      if (partNameKeys.some((partNameKey) => key?.includes(partNameKey))) {
        searchParams?.delete(key)
      }
    })
  }

  return searchParams?.size > 0 ? `?${searchParams?.toString()}` : ''
}
