import type { Currencies } from '@patrianna/shared-patrianna-types/store/CurrenciesModule'

import type { DialogNames } from 'store/types'

export const getActiveCurrency = (currencyName: string | string[] | undefined): Currencies | null => {
  if (!Array.isArray(currencyName)) {
    return currencyName ? 'SC' : 'GC'
  }

  return null
}

export const getLowOnCoinsDialogName = (activeCurrency: Currencies | null): DialogNames => {
  return activeCurrency === 'SC' ? 'NOT_ENOUGH_FUNDS' : 'LOW_COINS_DIALOG'
}
