import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuid } from 'uuid'

import type { FreeSpinsState } from 'store/types/FreeSpinsModule'
import type { FreeSpinWithIdType } from 'store/types/FreeSpinsModule'

const initialState: FreeSpinsState = {
  freeSpins: [],
}

export const freeSpinsSlice = createSlice({
  name: 'freeSpins',
  initialState,
  reducers: {
    saveFreeSpins: (
      state,
      action: PayloadAction<{
        freeSpins: FreeSpinWithIdType[]
        clearBeforeSave?: boolean
      }>
    ) => {
      const freeSpinsWithId = action.payload?.freeSpins.map((fs) => ({
        ...fs,
        id: uuid(),
      }))
      state.freeSpins = action.payload?.clearBeforeSave ? freeSpinsWithId : [...state.freeSpins, ...freeSpinsWithId]
    },
  },
})

export type ActionsTypes = ReturnType<InferValueTypes<typeof freeSpinsSlice.actions>>
export const { actions } = freeSpinsSlice
