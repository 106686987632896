import type { InvitationBonusInfo } from '@patrianna/shared-patrianna-types/store/AccountBonusesModule'
import type { BaseBonus, InvitationBonus } from '@patrianna/shared-patrianna-types/store/BonusesFlowModule'
import type {
  AcceptDailyBonusRequest,
  AcceptInvitationBonusRequest,
  AcceptManualBonusRequest,
  AcceptSocialMediaRewardRequest,
} from '@patrianna/shared-patrianna-types/websocket/requests'
import type {
  AcceptDailyBonusResponse,
  AcceptInvitationBonusResponse,
  AcceptManualBonusResponse,
  AcceptSocialMediaRewardResponse,
} from '@patrianna/shared-patrianna-types/websocket/response'

import getCountryConfigClient from 'app/utils/getCountryConfig/getCountryConfigClient'
import { trackEvent } from 'config/analytic'
import getAccountBonusesRequest from 'services/gateway/requests/getAccountBonuses'
import type { ReduxState, TypedThunk } from 'store'
import { getAccountInvitations } from 'store/modules/accountInvitations/actions'
import { setHistorySessionDialogs, waitingForSessionDialogsData } from 'store/modules/sessionDialogs/actions'
import { openSnackbar } from 'store/modules/snackbar/actions'
import type { DialogNames } from 'store/types'
import { removeDataFromSessionStorage, REWARD_CODE } from 'utils/sessionStorage'

import { sweepstakeEnabledSelector } from '../appConfig/selectors'
import { openDialog, removeDialogByName } from '../dialog/actions'
import { getDialogVisibilityByNameSelector } from '../dialog/selectors'

import {
  getGCManualBonusesSelector,
  getInvitationBonusesSelector,
  getManualBonusesSelector,
  getTodayBonusSelector,
  getWeeklyRewardBonusesSelector,
} from './selectors'
import { saveInvitatonBonuses, saveManualBonuses, setAcceptingDailyBonus, setSocialMediaReward } from './slice'

export const acceptBonusFlow =
  <T extends BaseBonus>(
    code: string,
    selector: (state: ReduxState) => T[] | null,
    saveFunction: (data: T[]) => any,
    modalName: DialogNames
  ): TypedThunk =>
  (dispatch, getState) => {
    const bonuses = selector(getState())
    const filteredBonuses = bonuses?.filter((el) => el.code !== code)

    if (filteredBonuses) {
      saveFunction(filteredBonuses)
      dispatch(removeDialogByName({ modalName }))
    }
  }

export const updateBonusesNotification =
  <T extends BaseBonus>(
    bonus: T,
    selector: (state: ReduxState) => T[] | null,
    saveFunction: (data: T[]) => any,
    checkUserBonus: () => any
  ): TypedThunk =>
  (dispatch, getState) => {
    const bonuses = selector(getState())
    const updatedBonuses = bonuses?.length ? [...bonuses.map((el) => ({ ...el })), bonus] : [bonus]

    saveFunction(updatedBonuses)
    dispatch(checkUserBonus())
  }

export const checkNextUserBonus = (code: string, callBack: (bonus: BaseBonus) => void) => (bonuses: BaseBonus[]) => {
  const currenctGameIndex = bonuses.findIndex((el) => el.code === code)
  if (currenctGameIndex < bonuses.length - 1) {
    const nextBonus = bonuses[currenctGameIndex + 1]
    callBack(nextBonus)
  }
}

export const checkRewardDialogAndOpen =
  (bonuses: BaseBonus[]): TypedThunk =>
  (dispatch, getState) => {
    const isBonusDialogOpened = getDialogVisibilityByNameSelector(getState(), 'REWARD_DIALOG')

    if (!isBonusDialogOpened) {
      dispatch(openDialog({ modalName: 'REWARD_DIALOG', dialogProps: { bonuses } }))
      dispatch(setHistorySessionDialogs({ modalName: 'REWARD_DIALOG' }))
    }
  }

export const checkInvitationRewardDialogAndOpen =
  (bonus: BaseBonus): TypedThunk =>
  (dispatch, getState) => {
    const isBonusDialogOpened = getDialogVisibilityByNameSelector(getState(), 'REWARD_FRIEND_DIALOG_SESSION')

    if (!isBonusDialogOpened) {
      dispatch(getAccountInvitations())
      dispatch(openDialog({ modalName: 'REWARD_FRIEND_DIALOG_SESSION', dialogProps: { bonus } }))
    }
  }

export const checkManualUserBonuses = (): TypedThunk => (dispatch, getState) => {
  const bonuses = getManualBonusesSelector(getState())
  const gcOnlyBonuses = getGCManualBonusesSelector(getState())
  const isScAvailable = sweepstakeEnabledSelector(getState())

  if (isScAvailable) {
    if (bonuses?.length) {
      dispatch(checkRewardDialogAndOpen(bonuses))
    }
  } else if (gcOnlyBonuses?.length) {
    dispatch(checkRewardDialogAndOpen(gcOnlyBonuses))
  }
}

export const checkInvitationUserBonuses = (): TypedThunk => (dispatch, getState) => {
  const bonuses = getInvitationBonusesSelector(getState())
  const isScAvailable = sweepstakeEnabledSelector(getState())

  if (isScAvailable && bonuses?.length) {
    dispatch(checkInvitationRewardDialogAndOpen(bonuses[0]))
  }
}

export const openNextInvitationBonusDialog =
  (code: string): TypedThunk =>
  (dispatch, getState) => {
    const openNextBonus = checkNextUserBonus(code, (bonus: BaseBonus) =>
      dispatch(
        openDialog({
          modalName: 'REWARD_FRIEND_DIALOG_SESSION',
          dialogProps: {
            bonus,
          },
        })
      )
    )
    const bonuses = getInvitationBonusesSelector(getState())
    if (bonuses) {
      openNextBonus(bonuses)
    }
  }

// acceptt and  ubdate bonus functions
export const acceptManualBonusResponse =
  (code: string): TypedThunk =>
  (dispatch) => {
    const saveBonuses = (bonuses: BaseBonus[]) => {
      dispatch(
        saveManualBonuses({
          manualBonuses: bonuses,
        })
      )
    }

    dispatch(acceptBonusFlow(code, getManualBonusesSelector, saveBonuses, 'REWARD_DIALOG'))
  }

export const acceptInvitationBonusResponse =
  (code: string): TypedThunk =>
  (dispatch) => {
    const saveBonuses = (bonuses: Array<InvitationBonus | InvitationBonusInfo>) => {
      dispatch(
        saveInvitatonBonuses({
          invitationBonuses: bonuses,
        })
      )
    }
    dispatch(acceptBonusFlow(code, getInvitationBonusesSelector, saveBonuses, 'REWARD_FRIEND_DIALOG_SESSION'))
  }

export const updateManualBonusesNotificationListener =
  (bonus: BaseBonus): TypedThunk =>
  (dispatch) => {
    const saveBonuses = (bonuses: Array<BaseBonus>) => {
      dispatch(
        saveManualBonuses({
          manualBonuses: bonuses,
        })
      )
    }
    dispatch(updateBonusesNotification(bonus, getManualBonusesSelector, saveBonuses, checkManualUserBonuses))
  }

export const updateInvitationBonusesNotificationListener =
  (bonus: InvitationBonus | InvitationBonusInfo): TypedThunk =>
  (dispatch) => {
    const saveBonuses = (bonuses: Array<InvitationBonusInfo>) => {
      dispatch(
        saveInvitatonBonuses({
          invitationBonuses: bonuses,
        })
      )
    }
    dispatch(updateBonusesNotification(bonus, getInvitationBonusesSelector, saveBonuses, checkInvitationUserBonuses))
  }

export const acceptDailyBonus =
  (): TypedThunk =>
  (dispatch, getState, { gateway, errorHandler }) => {
    const todayBonus = getTodayBonusSelector(getState())
    const scEnabled = sweepstakeEnabledSelector(getState())

    if (todayBonus) {
      const data: AcceptDailyBonusRequest = {
        type: 'AcceptDailyBonusRequest',
        code: todayBonus.code,
      }

      dispatch(setAcceptingDailyBonus({ accepting: true }))

      gateway
        .emit<AcceptDailyBonusResponse>(data)
        .then(() => {
          dispatch(
            getAccountBonusesRequest(() => {
              dispatch(setAcceptingDailyBonus({ accepting: false }))
            })
          )
        })
        .catch((err) => {
          dispatch(setAcceptingDailyBonus({ accepting: false }))
          dispatch(errorHandler(err, data))
        })
        .finally(() => {
          dispatch(removeDialogByName({ modalName: 'DAILY_REWARD_DIALOG' }))
        })

      if (scEnabled) {
        trackEvent('sc_daily_bonus_accepted', { category: 'daily SC reward accepted', label: todayBonus.code })
      } else {
        trackEvent('gc_daily_bonus_accepted', { category: 'daily GC reward accepted', label: todayBonus.code })
      }
    } else {
      dispatch(removeDialogByName({ modalName: 'DAILY_REWARD_DIALOG' }))
    }
  }

export const acceptUserBonusByTypeRequest =
  (code: string): TypedThunk =>
  (dispatch, getState, { gateway, errorHandler }) => {
    const manualBonuses = getManualBonusesSelector(getState())
    const invitationBonuses = getInvitationBonusesSelector(getState())
    const weeklyRewardBonuses = getWeeklyRewardBonusesSelector(getState())

    if (manualBonuses?.find((el) => el.code === code) || weeklyRewardBonuses?.find((el) => el.code === code)) {
      const data: AcceptManualBonusRequest = { type: 'AcceptManualBonusRequest', code }

      gateway
        .emit<AcceptManualBonusResponse>(data)
        .then((body) => {
          dispatch(acceptManualBonusResponse(body.code))
        })
        .catch((err) => {
          dispatch(errorHandler(err, data))
        })
    } else if (invitationBonuses?.find((el) => el.code === code)) {
      const data: AcceptInvitationBonusRequest = { type: 'AcceptInvitationBonusRequest', code }

      gateway
        .emit<AcceptInvitationBonusResponse>(data)
        .then((body) => {
          dispatch(acceptInvitationBonusResponse(body.code))
        })
        .catch((err) => {
          dispatch(errorHandler(err, data))
        })
    }
  }

export const checkWeeklyRewardBonuses = (): TypedThunk => (dispatch, getState) => {
  const bonuses = getWeeklyRewardBonusesSelector(getState())
  const bonus = bonuses?.[0]
  const isLoyaltyProgramEnabled = process.env.LOYALTY_PROGRAM_ENABLED
  const { vipWeeklyRewardFeature } = getCountryConfigClient()

  if (vipWeeklyRewardFeature.enabled && bonus && isLoyaltyProgramEnabled) {
    dispatch(openDialog({ modalName: 'VIP_WEEKLY_REWARD_DIALOG', dialogProps: { bonus } }))
  }
}

export const getSocialMediaRewardRequest =
  (rewardCode: string): TypedThunk =>
  (dispatch, getState, { gateway }) => {
    const data: AcceptSocialMediaRewardRequest = {
      type: 'AcceptSocialMediaRewardRequest',
      code: rewardCode,
    }
    gateway
      .emit<AcceptSocialMediaRewardResponse>(data)
      .then(({ scAmount, gcAmount }) => {
        dispatch(setSocialMediaReward({ reward: { scAmount, gcAmount } }))
      })
      .catch((err) => {
        dispatch(
          openSnackbar({
            message: err?.status?.errorText,
            action: {
              text: '',
              action: () => {
                dispatch(setSocialMediaReward({ reward: null }))
                removeDataFromSessionStorage(REWARD_CODE)
              },
            },
            closeWhenPathChanged: false,
          })
        )
      })
      .finally(() => {
        dispatch(waitingForSessionDialogsData({ socialMediaReward: true }))
      })
  }
