import type { GameProduct, GameTypes } from '@patrianna/shared-patrianna-types/store/GameModule'

export const convertArrayToObject = (arr: any, key: string) => {
  return (
    arr &&
    arr.reduce((acc: any, item: any) => {
      acc[item[key]] = {
        ...item,
      }

      return acc
    }, {})
  )
}

const sortByOrder = (a: GameProduct, b: GameProduct) => a.rank - b.rank

// @TODO hide virtual lotto
export const sortGamesByType = (games: Array<GameProduct>) => {
  const categorizedGames = games.reduce(
    (acc: any, item: any) => {
      let game = item
      if (['table_games', 'video_slots', 'scratch_card'].includes(item.type)) {
        game = { ...item, type: 'slots' }
      }

      // other categories with single type
      if (!acc[game.type]) {
        acc[game.type] = []
      }
      acc[game.type].push(game)

      return acc
    },
    {} as Record<GameTypes, Array<GameProduct>>
  )

  const keys = Object.keys(categorizedGames)

  keys.map((k: GameTypes) => categorizedGames[k].sort(sortByOrder))

  return categorizedGames
}

export const isUnlimitedPlayGame = (game: GameProduct) => game.tags?.includes('infinite')
