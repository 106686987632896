import type { GameProduct } from '@patrianna/shared-patrianna-types/store/GameModule'
import type { GetAccountFreeSpinsRequest } from '@patrianna/shared-patrianna-types/websocket/requests'
import type { GetAccountFreeSpinsResponse } from '@patrianna/shared-patrianna-types/websocket/response'

import type { TypedThunk } from 'store'
import { sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'
import { getFreeSpinsScratchCardsSelector, getFreeSpinsSlotGamesSelector } from 'store/modules/freeSpins/selectors'

import { waitingForSessionDialogsData } from '../sessionDialogs/actions'

import { actions } from './slice'

export const { saveFreeSpins } = actions

export const getUserFreeSpins =
  (isScEnabled: boolean, clearBeforeSave: boolean = false): TypedThunk =>
  (dispatch, getState, { gateway, errorHandler }) => {
    const data: GetAccountFreeSpinsRequest = {
      type: 'GetAccountFreeSpinsRequest',
      currency: isScEnabled ? ['SC', 'GC'] : ['GC'],
    }

    gateway
      .emit<GetAccountFreeSpinsResponse>(data)
      .then((respBody) => {
        dispatch(saveFreeSpins({ freeSpins: respBody?.data, clearBeforeSave }))
        dispatch(waitingForSessionDialogsData({ freeSpins: true }))
      })
      .catch((err) => {
        dispatch(errorHandler(err, data))
      })
  }

export const updateFSBalance =
  (gameProduct: GameProduct): TypedThunk =>
  (dispatch, getState) => {
    const isScEnabled = sweepstakeEnabledSelector(getState())
    const freeSpinScratchCards = getFreeSpinsScratchCardsSelector(getState())
    const freeSpinSlotGames = getFreeSpinsSlotGamesSelector(getState())
    const freeSpins = [...freeSpinScratchCards, ...freeSpinSlotGames]
    if (freeSpins.some((fs) => fs?.products?.[0]?.code === gameProduct?.code)) {
      dispatch(getUserFreeSpins(isScEnabled, true))
    }
  }
